.alchemy-title{
    color: #fee0b8;
    font-size: 1.75rem;
    text-transform: uppercase;
}

.alchemy-text{
    color: #fee0b8;
}

.blood-button{
    margin-top: 1rem;
}

.empty-box {
    height: 300px;
    width: 300px;
    outline: solid 5px rgba(172, 92, 52, 1);
    background-color: #1D1714;
  }

  .selected-dragon{
    width: 300px;
    height: auto;
    position: relative;
  }


  .selected-weapon{
    height: auto;
    width: 300px;
  }

.loading-dice{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.remove-pane-button{
    margin-left: 10px;
    cursor: pointer;
}

.clear-button{
    background-color: #6B3321;
    color: white;
}

#clearDragons{
    border: none;
    cursor: pointer;
    &:hover{
        color: #FFB45C;
    }
}

.weapon-list-container{
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.selected-image{
    outline: solid 5px rgba(172, 92, 52, 1);
    max-width: 300px;
}

.dragon-label{
    position: absolute;
  bottom: 14px;
  left: 4px;
  width: 100%;
  text-align: center;
//   background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 8px;
  font-size: 14px;
}

#addButton{
    cursor: pointer;
}

#rerollButton{
    cursor: pointer;
}

.token-img{
    cursor: pointer;
}

.weapon-image-small{
    cursor: pointer;
    outline: solid 3.5px rgba(172, 92, 52, 1);

}

.reroll-percentage-title{
    color: #fee0b8;
}
.reroll-percentage-subtitle{
    color: #fee0b8;
}

.item-count-weapon-list{
    font-size: 12px;
}

.legendary-dragon{
    filter: grayscale(100%);
    pointer-events: none;
}

.no-balance{
    filter: grayscale(100%);
    pointer-events: none;
}

.dragonhorde-label{
   text-align: center;
}

.dragon-images-modal-container{
    display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.dragon-image-modal{
    width: 200px;
    height: auto;
    margin: 0 5px;
}

.rarity-label-modal{
    text-align: center;
}