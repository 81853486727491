.den-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.navbar-start {
  padding-bottom: none !important;
}

.navbar {
  background-color: transparent;
  padding-top: 2vh;
}

.nav-img {
  margin-top: 1vh;
  margin-right: 1vw;
  margin-bottom: 4vh;
  width: 90%;
}

.img-grey {
  filter: grayscale(100%);
}

.moon-img {
  position: fixed;
  top: 1vw;
  left: 1vw;
  width: 3vw;
}

.social-links {
  position: absolute;
  top: 1vw;
  right: 1vw;
  display: flex;
  align-items: center;
  z-index: 100;
}

.wallet-address {
  margin-left: 10px;
}

.social-icon {
  width: 60px;
  vertical-align: middle;
  padding-left: 10px;
}
