.loading-bar {
  position: relative;
  width: 100%;
  height: 20px;
  margin: 8px 0;
  background-color: white;
  border: solid 2px rgba(172, 92, 52, 1);
}

.loading-bar-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #4caf50;
}

.loading-bar-img {
  width: 75%;
}

.number-staked {
  font-size: 0.85rem;
  margin-top: 1vh;
  text-transform: uppercase;
}
