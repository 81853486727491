@font-face {
  font-family: "moonrunners";
  src: url("./assets/moonrunners-font.ttf") format("truetype");
}

body,
html {
  height: 100%;
  width: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  font-family: "moonrunners";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  width: 100%;
  background-color: black;
}

.small-screen-div {
  display: none;
}

.app-subheading {
  margin-top: 5vh;
  text-align: center;
}

.app-body {
  margin-top: 5vh;
}

.token-number {
  font-size: 1.5rem;
}

.container {
  max-width: 90%;
}

.raffles-filter.columns {
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .navbar-menu {
    display: flex !important;
    background-color: transparent !important;
    padding: 0 !important;
  }

  .nav-img {
    width: 200px !important;
    height: 70px !important;
    margin: 0 !important;
  }

  .navbar-start {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .root-body {
    display: none;
  }

  .small-screen-div {
    display: block;
    padding-top: 90px;
    text-align: center;
    width: 60%;
  }

  .layout-container {
    padding-top: 50px;
  }
}


@media only screen and (max-width: 768px) {

  .gutter {
    padding: 5vh !important;
  }

  .container {
    max-width: 650px;
  }

  .columns {
    display: flex;
  }

  .column {
    padding: 0.4rem !important;
  }

  .staking-title {
    font-size: 16px !important;
  }

  .connect-btn {
    width: 220px;
  }

  .loading-bar {
    width: 200px !important;
  }

  .buy-text {
    margin: 10px 0;
  }

  .raffles-filter.columns {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .raffles-filter .column {
    flex-grow: 0;
  }

  .raffles-box .column {
    padding: 1rem !important;
  }

  .winner.container {

  }

}

@media only screen and (max-width: 680px) {

  .navbar-start {
    gap: 16px;
  }

  

  .nav-img {
    width: 160px !important;
    height: 50px !important;
  }

  .container {
    max-width: 350px;
  }

  .trophy-img-wrapper.container {
    flex-wrap: wrap;
    width: auto !important;
  }

  .raffles-box .column {
    padding: 2.7rem !important;
  }

  .raffle-object {
    background-color: rgba(172, 92, 52, 0.25);
    outline: solid 4px rgba(172, 92, 52, 1);
    color: white;
    margin-top: 50px;
    font-size: 0.8rem;
    padding: 2.7rem !important;
    min-height: 450px !important;
    height: auto;
  }

  .trophy-img-wrapper {
    flex-direction: row !important;
  }

  .trophy-img-wrapper .trophy-row {
    min-width: 50%;
  }

  .small-screen-div {
    width: 90%;
  }

  .raffles-title {
    font-size: 1.3rem !important;
  }

  .staking-body {
    margin-top: 20px;
  }

  .columns {
    flex-direction: column;
  }

  .heading {
    font-size: 20px !important;
  }

  p {
    font-size: 14px;
  }

  .section .container {
    width: 100%;
  }

  .section .col-right {
    margin-left: 0;
  }

  .section .has-text-centered {
    text-align: left !important;
  }

  .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .section {
    font-size: 14px;
  }

  .gutter {
    padding-bottom: 15vh;
  }

  .moon-img {
    width: 40px !important;
    padding: 5px;
    z-index: 1;
  }

  .raffles-filter.columns {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .raffles-info-report{
    font-size: 0.8rem;
    text-align: center;
  }
  
  .nav-img {
    width: 130px !important;
  }
}

@media only screen and (max-width: 320px) {
 

  .layout-container {
    min-height: calc(100vh - 190px) !important;
  }

  .container {
    max-width: 80% !important;
  }

  .buy-text,
  .staking-title {
    font-size: 14px;
  }

  .buy-text {
    padding: 2px
  }

  .raffles-box .column {
    padding: 1.7rem !important;
  }

  .raffles-info-report{
    font-size: 0.8rem;
    text-align: center;
  }
  .raffles-filter.columns {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .raffles-title {
    font-size: 1.3rem !important;
  }
  
}