.warningMessage {
  color: red;
}

.bloodImages {
  display: flex;
  gap: 20px;
}

.bloodImage {
  padding: 10px;
}

.buttons {
  display: flex;
  align-items: center;
}

.weaponInfo {
  border: solid 5px rgba(172, 92, 52, 1);
  padding: 6px;
  cursor: pointer;
  margin-left: 10px;
}

.bigModal {
  width: 80%;
}

.center {
  text-align: center;
}
